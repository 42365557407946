import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'react-bootstrap'

import './style.css'

function FooterBtn({path, label}){
    return(
        <Link to={path} style={{float:"left"}}>
            <Button variant="primary" className="footerbtn">
                {label}
            </Button>
        </Link>
    )    
}

export default FooterBtn;
import React, { useState, useEffect } from "react"
import { Row } from "react-bootstrap"

import TweetQuestion from "../tweetquestion"
import Book from "../book"
import './style.css'

const Thread = ({parentTweet, woa}) =>{
    return(
        <React.Fragment>
            <Row>
                <TweetQuestion tweet={parentTweet}/>
            </Row>
            <Row>
                <h1 style={{marginTop:"2rem", marginBottom:"1rem"}}>Book mentions in this thread</h1>
            </Row>
            <Row>
                <ul className="booklist">
                    {woa.map( woa_item => {
                        const book = woa_item.books[0]
                        const score = woa_item.score
                        if(typeof book != "undefined" && 
                            book != null && 
                            book.hasOwnProperty('isbn'))
                        {
                            const id = parentTweet.tweet_id_str + '_' + book.isbn
                            return(
                            <li key={id}>
                                <Book book={book} showDescription={true} score={score}/>
                            </li>
                            )
                        }        
                    })}
                </ul> 
            </Row> 
        </React.Fragment>
    )
}

export default Thread;
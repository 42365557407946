import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FeedItem from "../components/feeditem"
import { Router } from "@reach/router"
import Thread from "../components/thread"

function Feed({data}){
  const schema = 
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": data.site.siteMetadata.title,
    "url": data.site.siteMetadata.siteUrl,
    "description": data.site.siteMetadata.description,
    "sameAs": "https://twitter.com/BooksChatterBot"
  }
    
  return(
      <Layout>
          <Router>
            <Thread path="/thread/:id"/>
          </Router>
          <SEO title="BooksChatter | Top Book Recommendations by Experts" schemaMarkup={schema}/>
          <ul style={{ padding: 0 }}>
              {data.BookRecos.edges.map( reco => {
                return(
                  <li
                  key={reco.node.id}
                  style={{
                      textAlign: "center",
                      listStyle: "none",
                      display: "block"
                  }}
                  >
                      <FeedItem reco={reco.node} />
                  </li>
              )}
            )}
          </ul>
      </Layout>
  )
}

export const query = graphql`
  {
    BookRecos: allReco(filter: {created_at: {ne: null}}, sort: {fields: created_at, order: DESC}, limit: 30) {
        edges {
          node {
            id
            created_at
            text
            tweet_id
            user_screen_name
            ...BookFragment
            ...InfluencerFragment
          }
        }
      }
    site: site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }  
  }
`
export default Feed;
import React from 'react'
import { Card } from "react-bootstrap"

import Reco from "../reco"
import { hyphenatedName } from '../../services/format'
import FooterBtn from '../ui/footerbtn'
import './style.css'

function FeedItem({reco}){
    const { tweet_id, bookObjects, influencer } = reco
    const { display_name, user_name } = influencer
    const influencer_name = ( display_name ? display_name : user_name)
    const influencer_hyphen_name = hyphenatedName(influencer_name)
    const hyphen_title = hyphenatedName(bookObjects[0].title)

    return(
        <Card id={ tweet_id + '_' + hyphen_title} style = {{marginBottom: "30px"}}> 
            <Card.Body>
                <Reco reco={reco} />  
            </Card.Body> 
            <Card.Footer className="footer">
                <FooterBtn path={"/books/" + influencer_hyphen_name}
                label={`More by ${display_name? display_name: user_name}`}/>
                {bookObjects[0].categoryMapping ? 
                    <FooterBtn path={"/best-books/" + bookObjects[0].categoryMapping.urlName}
                    label={`More in ${bookObjects[0].categoryMapping.displayName}`} />
                 : null}
            </Card.Footer>                 
        </Card>
    )
}

export default FeedItem

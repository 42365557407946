import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import Thumbnail from '../thumbnail'
import BookDescription from "../bookdescription"
import BuyButtonBar from '../ui/buybuttonbar'

import { formatName } from '../../services/format'
import './style.css'

function Book({book, showDescription=false, score=0}){
    const author = formatName(book.author)
    
    return(
        <div>
            <Row className="profilePage">
                <Col xs={5} sm={5} lg={2}>
                    <Row style={{padding: "5px"}}>
                    { book.geniusLink ? <a href={book.geniusLink} target="_blank" rel="noreferrer"><Thumbnail thumbnail={ book.thumbnail } alt={book.title}/></a>:
                      book.affiliateLink ? <a href={book.affiliateLink} target="_blank" rel="noreferrer"><Thumbnail thumbnail={ book.thumbnail } alt={book.title}/></a>:
                      <Thumbnail thumbnail={ book.thumbnail } alt={book.title}/>
                    }
                    </Row>
                    {score >0 ?
                        <h3 style={{fontSize:"1.5rem"}}>Votes: {score}</h3>
                    :null
                    }
                </Col>
                <Col xs={7} sm={7} lg={10}>
                    <h1 className="book-title">{book.title}</h1>
                    {author ? <h2 className="author">by {author}</h2>:null }
                    {book.categoryMapping? <p>Category: <Link to={"/best-books/" + book.categoryMapping.urlName}>{book.categoryMapping.displayName}</Link></p>:null}
                    {showDescription ? 
                        <Row className="description">
                            <Col xs={12}>
                                <BookDescription book={book}/>
                            </Col>
                        </Row>
                    :null}
                    <Row>
                        <Col xs={12}>
                            <BuyButtonBar book={book} />
                        </Col>
                    </Row>
                    
                </Col>        
            </Row>
        </div> 
         
    )
}

export default Book
import React from 'react'
import { Link } from 'gatsby'
import { Row, Col, Image } from 'react-bootstrap'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { formatDate } from '../../services/format'

import {FaTwitter} from 'react-icons/fa'

import './style.css'

function TweetQuestion({tweet}){
    const { tweet_id_str, text, user_name, user_screen_name, created_at } = tweet
    const tweet_date = formatDate(created_at)
    const profile_image = 'https://unavatar.now.sh/'+user_screen_name

    return (
            <Row className="twitter-tweet">
                <Row className="left-space">
                    <Col xs={7} sm={8} className="influencer" style={{paddingLeft:0}}>
                        <div className="name"> 
                            {user_name}
                        </div>
                        <div>
                            <a href={"https://twitter.com/" + user_screen_name } target="_blank" rel="noreferrer">@{user_screen_name}</a>
                        </div>   
                    </Col>
                    <Col className="recotweet" style={{textAlign:"right"}}>
                        <a href={"https://twitter.com/" + user_screen_name + "/status/" + tweet_id_str} target="_blank" rel="noreferrer"><FaTwitter/></a>
                    </Col>
                </Row>
                <Row className="text">
                    <p style={{margin:"10px 0px 10px 5px", fontSize:"1.25rem"}}>{text}</p>
                </Row>
                <Row className="footer">
                    <p className="left-space">{tweet_date}</p>
                </Row>
            </Row> 
    )
}

export default TweetQuestion;